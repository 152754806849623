// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/" !default;
$text-color: #636b6f;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$grey: #6c757d;

// Brands
$brand-primary: #497C99;
$brand-info: #6eb1da;
$brand-success: #2ab27b;
$brand-warning: #ea9000;
$brand-danger: #bf2929;

// Navbar
$navbar-default-bg: $brand-primary;
$navbar-color: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Locations
$location-table-radius: 15px;

// CUSTOM FILE UPLOAD
$custom-file-text: (
        en: "Browse",
        es: "Elegir",
        ca: "Escollir"
);
