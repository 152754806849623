// Variables
@import '../variables';

/* BUTTONS */
.btn-primary {
  @include button-variant($brand-primary, darken($brand-primary, 7.5%), darken($brand-primary, 10%), lighten($brand-primary,5%), lighten($brand-primary, 10%), darken($brand-primary,30%));
}

.btn-outline-primary {
  @include button-outline-variant($brand-primary, #222222, lighten($brand-primary,5%), $brand-primary);
}

// TABLES
tbody.align-middle td{
  vertical-align: middle;
}

// CARDS
.card{
    border: none;
}
.card-header{
    background: $brand-primary;
    color: $navbar-color;
    border: none;
    border-top-left-radius: $location-table-radius !important;
    border-top-right-radius: $location-table-radius !important;
}
.card-body{
    border: 1px solid rgba(0, 0, 0, 0.125);
}
h3.card-title{
  margin-bottom: 3px;
  margin-top: 3px;
}
.card-header {
  padding: 0.55rem 1.25rem;
}

.custom-file *, .custom-file{
  cursor: pointer !important;
}

//NAVBAR

.navbar-light{
  background: #fff;
  padding: 0;
}
.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-brand{
  color: $navbar-color;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 20px;
  padding-right: 20px;

  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -ms-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus{
  color: $navbar-color;
  opacity: 0.8;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav>.active>a, .navbar-light .navbar-nav>.active>a:focus, .navbar-light .navbar-nav>.active>a:hover{
  color: $navbar-color;
  background: darken( $brand-primary, 10% );
}
.navbar-brand{
  padding: 0 !important;
}

.navbar-toggler{
  margin: 7px 15px;
  background: #f1f1f1;
}

.navbar-nav>li>.dropdown-menu {
  margin-top: 0;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-item{
    display: inline-flex !important;
}

.nav-item.active .nav-link{
    color: #fff !important;
    background-color: $brand-primary !important;
}

.nav-link{
    color: $text-color !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.nav-link.active{
    color: #FFFFFF !important;
    background-color: $brand-primary !important;
}

#login-layout .card .card-body{
  padding: 0px 1.25rem 0px 1.25rem;
}
// MODAL
.modal-header{
    background-color: #dddddd;
    padding-top: 10px;
    padding-bottom: 10px;
}
.modal-header .close {
    padding: 0rem 1rem;
    margin: auto -1rem auto auto;
}

// MEDIA
@media screen and (min-width: 1200px){
  .container {
    max-width: 90%;
  }
}
@media screen and (max-width: 767px){
  .container {
    max-width: 100%;
  }
  .navbar-brand{
    margin-left: 15px;
  }
  .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-brand{
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-collapse.collapse.show .dropdown-menu, .navbar-collapse.collapse.show .dropdown-menu a{
    background: transparent;
    border: none;
    color: $navbar-color;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
  }
  .navbar-collapse.collapse.show .dropdown-menu a:hover, .navbar-collapse.collapse.show .dropdown-menu a:focus, .navbar-collapse.collapse.show .dropdown-menu a:active{
    opacity: 0.8;
  }
}
