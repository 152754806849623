// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import '../variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Bootstrap overrides
@import "bootstrap-override";

// General app styles
@import "general";

// Datepicker
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

// Bootstrap Toggle
@import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';

// Bootstrap Select
@import '~bootstrap-select/sass/bootstrap-select.scss';

// Responsive Tabs
@import '~bootstrap-responsive-tabs/dist/css/bootstrap-responsive-tabs.css';

// Sweet Alert 2
@import '~sweetalert2/src/sweetalert2.scss';