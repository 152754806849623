body{
  padding-bottom: 50px;
}
.logo-image, .logo-image-container{
  max-width: 100%;
  height: 50px;
  vertical-align: inherit;
  margin: 10px 0px 0px 0px;
}

.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  z-index: 10;
}
.footer .container img.logo-technodac{
  margin: 9px 0 10px;
  height: 20px;
}
.footer .container p{
  font-weight: lighter;
  margin: 7px 0 6px;
  font-size: 16px;
}
.footer{
  background-color: $brand-primary+b3;
  border-top: 2px solid $brand-primary;
}
.footer .container p a{
  color: #fff;
}
.footer .container p{
  color:#fff;
}

.panel-default > .panel-heading h3 {
  font-size: 23px;
  font-weight: lighter;
  line-height: 33px;
}
.inline-block {
  display: inline-block;
  vertical-align: top;
}
.clear-form:not(:last-child) {
  margin-right: 15px;
}
#pagination-rows-number{
  width: 90px;
  margin: 0 auto;
}
#pagination-rows-number select{
  text-align: center;
}

h1{
  color: $brand-primary;
}
.actions-block{
  margin-bottom: 10px;
}
.list-page .actions-block{
  margin-top: 24px;
  margin-bottom: 0px;
}
.actions-block button, .actions-block a{
  margin-bottom: 10px;
}
.sortable-handle {
  cursor: move;
}
.sortable-handle {
  color: $brand-primary;
}
.bordered-title{
  border-bottom: 1px solid $text-color;
  margin-bottom: 20px;
}
.flex-end{
  align-self: flex-end !important;
}

.width-column-id {
  width: 80px;
}
.width-column-year{
    width: 80px;
}
.width-column-participants{
    width: 120px;
}
.width-column-sort{
  width: 100px;
}
.width-column-profile-image{
  width: 100px;
}
.profile-image-list{
  width: 50px;
  height: 50px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

img{
  max-width: 100%;
}
img.image-preview {
  max-width: 120px;
  max-height: 80px;
  cursor: pointer;
}
.table-td-div-color {
  //width: 40px;
  //height: 30px;
  max-width: 200px;
  border-radius: 3px;
  color: #fff;
  padding: 3px 10px;
  display: inline-block;
}
.align-middle{
  vertical-align: middle;
}

.background-success{
  background-color: $brand-success;
}
.background-danger {
  background-color: $brand-danger;
}

.error{
  border-color: red;
}
label.error{
  color: red;
}
.row-bold td{
  font-weight: bold;
}
.margin-like-h1{
  margin-top: 22px;
  margin-bottom: 11px;
}
.status-button{
  width: 46px;
  text-align: center;
}
.simple-info-container{
  margin-bottom: 30px;
  background: #f3f3f3;
  padding: 10px 15px;
  border-radius: 3px;
}
.errors-block .alert ul{
  margin-bottom: 0;
}

/* IMAGE UPLOADER TYPE PROFILE - START */
.image-editable-container {
  max-width: 280px;
  margin: 0 auto;
}
.image-editable{
  cursor: pointer;
  position: relative;
}
.image-editable .image-overlay, .image-modified-overlay{
  background: $brand-primary;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  transition: 0.3s all;
  border:1px solid #efefef;
  text-align: center;
}
.image-editable:hover .image-modified-overlay{
  opacity: 0 !important;
}
.image-editable:hover .image-overlay{
  opacity: 0.9;
}
.image-editable .image-overlay i{
  line-height: 0;
  padding: 50% 0;
  font-size: 75px;
  color: #fff;
}
.image-editable-label{
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  text-align: center;
}

.image-modified-overlay{
  opacity: 0;
  transition: 0.3s;
}
.vso-image-profile {
  width: 100%;
  padding-top: 100%;
  height: auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-position: center center;
  margin: 0 auto;
  background-size: cover !important;
}
/* IMAGE UPLOADER TYPE PROFILE - END */

/* BOOTSTRAP 4 RESPONSIVE TABS HACK - START */
.tab-content > div.active {
  display: block;
}
.tab-content > .active {
  display: none;
}
ul.responsive-tabs{
  margin-bottom: 10px;
}
/* BOOTSTRAP 4 RESPONSIVE TABS HACK - END */

/* IMAGE UPLOADER TYPE NORMAL - START */
.images-uploader-container .images-uploader-item-container{
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #e7e7e7;
  border: 1px solid #cccccc;
  padding: 20px;
}
.images-uploader-container .images-uploader-item{
  margin-bottom: 30px;
}
.new-image {
  max-width: 100%;
  height: auto;
  width: auto;
}
.image-uploader-preview, .new-image{
  max-height: 500px;
}
.new-image-container{
  margin-bottom: 15px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.fileupload-exists.fileupload .btn{
  width: 49%;
  vertical-align: top;
  margin-top: 0;
  display: inline-block;
}
.fileupload-exists.fileupload .add-change-button{
  margin-right: 1%;
}
.fileupload-exists.fileupload .delete-button{
  margin-left: 1%;
}

input[type="text"]:disabled {
    color: #b1b7ba;
}

.btn-file{overflow:hidden;position:relative;vertical-align:middle;}.btn-file>input{position:absolute;top:0;right:0;margin:0;opacity:0;filter:alpha(opacity=0);transform:translate(-300px, 0) scale(4);font-size:23px;direction:ltr;cursor:pointer;}
.fileupload{}
.fileupload .uneditable-input{display:inline-block;margin-bottom:0px;vertical-align:middle;cursor:text;}
.fileupload .thumbnail{overflow:hidden;display:inline-block;margin-bottom:5px;vertical-align:middle;text-align:center;}.fileupload .thumbnail>img{display:inline-block;vertical-align:middle;max-height:100%;}
.fileupload .btn{vertical-align:middle;}
.fileupload-exists .fileupload-new,.fileupload-new .fileupload-exists{display:none;}
.fileupload-inline .fileupload-controls{display:inline;}
.fileupload-new .input-append .btn-file{-webkit-border-radius:0 3px 3px 0;-moz-border-radius:0 3px 3px 0;border-radius:0 3px 3px 0;}
.fileupload .file-to-upload{display:none;}
.fileupload .file-to-upload i.fa{margin-right: 5px;}
.fileupload:not(.fileupload-new) .file-to-upload{margin-bottom: 20px; background: #fff;width: 100%;display: block;padding: 6px;border-radius: 3px;}
.thumbnail-borderless .thumbnail{border:none;padding:0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;}
.fileupload-new.thumbnail-borderless .thumbnail{border:1px solid #ddd;}
.control-group.warning .fileupload .uneditable-input{color:#a47e3c;border-color:#a47e3c;}
.control-group.warning .fileupload .fileupload-preview{color:#a47e3c;}
.control-group.warning .fileupload .thumbnail{border-color:#a47e3c;}
.control-group.error .fileupload .uneditable-input{color:#b94a48;border-color:#b94a48;}
.control-group.error .fileupload .fileupload-preview{color:#b94a48;}
.control-group.error .fileupload .thumbnail{border-color:#b94a48;}
.control-group.success .fileupload .uneditable-input{color:#468847;border-color:#468847;}
.control-group.success .fileupload .fileupload-preview{color:#468847;}
.control-group.success .fileupload .thumbnail{border-color:#468847;}
/* IMAGE UPLOADER TYPE NORMAL - END */

.logo-image-container{
    height: fit-content;
}

#right-side-navbar .show > .nav-link{
    background-color: #ffffff;
}
#right-side-navbar > li > .dropdown-menu{
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.main-menu-navbar-nav a{
    border-top-left-radius: $location-table-radius;
    border-top-right-radius: $location-table-radius;
}

.breadcrumb-container{
    color: #ffffff;
    background-color: $brand-primary;
    padding-top: 15px;
    padding-bottom: 15px;
}
.breadcrumb-container a{
    color: #ffffff;
}
.breadcrumb-register-action a{
    top: -7px;
}

.input-group-search{
    top: -7px;
}
.input-group-search i{
    color: #ffffff;
}

.input-group-search .input-group-append .form-control:focus{
    background-color: $brand-primary;
}

.btn-link-edit{
    color: $grey;
}
.btn-link-info{
    color: $brand-primary;
}
.btn-link-delete{
    color: $red;
}
.btn-link-edit:hover, .btn-link-delete:hover, .btn-link-info:hover{
    text-decoration:none;
}

.page-item.active .page-link {
    background-color: $brand-primary;
    border-color: $brand-primary;
}

.button-info-orange{
    color: white;
    background-color: darkorange;
}
.button-info-orange:hover{
    color: white;
    background-color: #DA7904;
}
.button-info-orange i{
    color: white;
}

/* Dropdown menu */
.dropdown-menu.inner.show{
    max-height: 500px;
}

/* Responsive */

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .main-menu-navbar-nav a{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}

@media (max-width: 576px) {

}

